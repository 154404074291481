<template>
  <div class="confirm-data-container">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading">
      <div class="row-container confirm-data__row">
        <div class="row-container__column">
          <i
            class="pi pi-check-circle"
            style="font-size: 8rem; color: #cf7e20; float: right"
          ></i>
        </div>
        <div class="column-container row-container__column">
          <h1 class="column-container__tittle">Looking good!</h1>
          <h3 class="column-container__sub-tittle">
            Make any edits you want, then submit your profile. <br />
            You can make more changes later.
          </h3>
          <button
            id="submit"
            @click="submit()"
            :disabled="isSubmiting"
            class="column-container__primary-button"
          >
            Submit profile
          </button>
        </div>
      </div>
      <div class="card confirm-data__card">
        <div class="row card__row">
          <img
            :src="profilePicture"
            alt="User icon"
            class="row__photo"
          />
          <h2>
            {{ registeredUser.first_name + " " }}
            {{
              registeredUser.second_name.length > 0
                ? registeredUser.second_name
                : registeredUser.second_name + " "
            }}
            {{ registeredUser.last_name }}
          </h2>
          <p>
            <i class="pi pi-map-marker"></i> {{ registeredUser.citizenship }}
          </p>
          <p>
            <i class="pi pi-phone"></i>
            {{ freelancer.freelancer.contact_phone }}
          </p>
        </div>
        <div class="row card__row">
          <p>
            Birth date:
            {{ " " + freelancer.freelancer.birth_date.split("T")[0] }}
          </p>
          <p>ID: {{ " " + freelancer.freelancer.dni }}</p>
          <p>E-mail: {{ " " + registerEmail }}</p>
        </div>
        <div class="row card__row">
          <p>
            Second contact phone:
            {{
              " " + freelancer.freelancer.second_contact_phone
                ? freelancer.freelancer.second_contact_phone
                : ""
            }}
          </p>
          <p>
            Alternative contact name:
            {{ " " + freelancer.freelancer.alternative_contact_name }}
          </p>
          <p>
            Alternative contact number:
            {{
              " " + freelancer.freelancer.alternative_contact_number
                ? freelancer.freelancer.alternative_contact_number
                : ""
            }}
          </p>
        </div>
      </div>

      <div
        v-for="specialization of freelancer.freelancer_experticies"
        :key="specialization.specialization_id"
        class="card confirm-data__card"
      >
        <i
          class="pi pi-pencil card__edit"
          @click="$router.push({ path: '/applicant-expertise' })"
        ></i>
        <div class="row card__row">
          <h2>
            Work experience - {{ " " + specialization.specialization_name }}
          </h2>
        </div>
        <div
          v-for="area of specialization.subcategory_experticies"
          :key="area.area_id"
        >
          <div class="row card__row">
            <p>{{ area.area_name }}</p>
            <p>Years of experience: {{ " " + area.experience }}</p>
          </div>
          <div class="row card__row">
            <p>Software: {{ " " + area.softwares.join(", ") }}</p>
            <p>Sector: {{ " " + area.sector }}</p>
            <p>Level: {{ " " + area.level_name }}</p>
          </div>
        </div>
      </div>

      <div class="card confirm-data__card">
        <i
          class="pi pi-pencil card__edit"
          @click="$router.push({ path: '/applicant-education' })"
        ></i>
        <div class="row card__row">
          <h2>Education history</h2>
        </div>
        <div class="row card__row">
          <ul>
            <li>High school: {{ " " + freelancer.education.high_school }}</li>
            <li>
              Bachelor degree: {{ " " + freelancer.education.bachelor_degree }}
            </li>
            <li>Master: {{ " " + freelancer.education.master }}</li>
            <li>Phd: {{ " " + freelancer.education.phd }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/Loading.vue";

export default {
  name: "ConfirmData",
  components: { Loading },
  data() {
    return {
      registerEmail: "",
      registeredUser: {},
      freelancer: {},
      isSubmiting: false,
      isLoading: false,
      profilePicture: null,
    };
  },
  created() {
    this.registeredUser = JSON.parse(
      localStorage.getItem("brains-user-registrated"),
    );
    this.getProfilePicture(this.registeredUser.photo);
    this.freelancer = JSON.parse(
      localStorage.getItem("freelance-registry-information"),
    );
    this.registerEmail = localStorage.getItem("brains-register-email");
  },
  methods: {
    async getProfilePicture(photoUrl) {
      this.profilePicture = await this.$store.dispatch("freelancer/getPhoto", photoUrl);
    },
    async submit() {
      this.isLoading = true;
      this.submitProfile(this.freelancer);
      this.isLoading = false;
    },

    async submitProfile(payload) {
      this.isSubmiting = true;
      const response = await this.$store.dispatch(
        "freelancer/registerFreelancer",
        payload,
      );
      if (response.status !== 200 && response.status !== 204) {
        this.$toast.add({
          severity: "error",
          summary: response,
          detail: "Error submiting freelancer data",
          life: 4000,
        });
        setTimeout(() => {
          this.isSubmiting = false;
        }, 4000);
      } else {
        this.$toast.add({
          severity: "success",
          summary: "Success Registry",
          detail: "Your freelance information was saved correcly",
          life: 4000,
        });
        setTimeout(() => {
          this.$router.push({ path: "/sign-up/" });
        }, 4000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card__edit {
  cursor: pointer;
}

.confirm-data-container {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.container-logo {
  display: grid;
  &__logo {
    height: 3rem;
    margin: 2rem;
  }
}

.confirm-data {
  width: 100%;
  max-width: 1500px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  &__card {
    border: 1px none #ccc;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow;
    width: 80%;
    background-color: white;
    margin: 2rem auto 0;
  }
}

.row-container {
  &__column {
    width: 48%;
  }
}

.column-container {
  &__tittle {
    margin-bottom: 0;
  }
  &__primary-button {
    @include primary-button();
  }
}

.card {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding: 0 2rem;
  }
  &__edit {
    float: right;
    margin: 1rem;
  }
}

.row {
  &__photo {
    height: 80px;
    margin-right: 1rem;
  }
}
</style>
